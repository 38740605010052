<template>
  <div class="content">
    <el-row :gutter="10" type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column prop="name" :label="t('interfence_rule.name')"></el-table-column>
      <el-table-column
        prop="lesion"
        :label="t('interfence_rule.lesion')"
      ></el-table-column>
      <el-table-column
        prop="level"
        :label="t('interfence_rule.level')"
      ></el-table-column>
      <el-table-column
        prop="weight"
        :label="t('interfence_rule.weight')"
      ></el-table-column>
      <el-table-column prop="type" :label="t('interfence_rule.type')">
        <template #default="scope">
          <span>{{ scope.row.type === "picture" ? "识图" : "问卷" }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { search} from "@/api/interfence_rule";
export default {
  name: "interfence_rule",
  setup() {
    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const handleSearch = (params) => {
      console.log("params", params);
      getInterfenceRuleData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getInterfenceRuleData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getInterfenceRuleData();
    };

    const getInterfenceRuleData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getInterfenceRuleData();
    });
    return {
      t,
      params,
      tableInfo,
      handleSearch,
      handleSizeChange,
      handleCurrentChange
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
